import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
// keywords: contested divorce uncontested divorce
export default () => (
	<Layout style={{ color: `teal` }}>
		<Helmet title="Divorce | RWK Attorneys" defer={false} />

		<div className="container">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><Link to="/">Home</Link></li>
					<li class="breadcrumb-item"><Link to="/practice-areas">Practice Areas</Link></li>
					<li class="breadcrumb-item"><Link to="/practice-areas/family-law">Family Law</Link></li>
					<li class="breadcrumb-item active" aria-current="page">Divorce</li>
				</ol>
			</nav>

			<h1>Divorce</h1>


			<h2>Contempt</h2>

Anyone can be held in contempt of court when they fail to meet various obligations required by a court order, such as paying child support or alimony.

If you are facing a contempt of court action, also known as a Rule Nisi, it is important that you take the case very seriously. Failure to properly respond can lead to very serious consequences, including garnishment of wages and tax refunds, and even incarceration.

Likewise, if your ex-spouse is not abiding by the court’s order to pay child support, alimony, or perform some other duty mandated by the court order, your only relief may be to invoke the contempt powers of the court in order to force compliance.

			<h2>Contested</h2>

Contested divorces traditionally involve situations where the parties cannot agree on some major point such as property division, alimony, custody, child support, or attorney’s fees. Often in these cases there are grounds for the divorce other than incompatibility, such as adultery, domestic violence, or drug addiction.

The question of “How long will this divorce take?” is easily one of the most frequently asked questions. The short answer is that is depends upon the willingness of both parties to sit down and negotiate a settlement in their case. It takes both parties to settle the divorce and have it move as expeditiously as possible. It only takes one person to drive up the costs and length of time it takes to get a divorce.

Divorce is difficult enough. Making a decision about hiring an Alabama divorce attorney should not add to this stress. During this difficult time, you need a divorce attorney who is understanding and compassionate, yet able to fight for your rights. The attorneys at Russo, White & Keller have practiced divorce law on a daily basis for well over 25 years. We will fight for your rights and what you deserve.

<h2>Uncontested</h2>
Often there is confusion about the difference between an uncontested divorce and a contested divorce. Generally speaking, an uncontested divorce is usually based upon incompatibility and the parties have reached an overall consensus of how their property should be separated and how to handle custody, visitation, child support, alimony, and other issues. If both parties agree on all issues, it can save the parties money, emotional stress, and future abilities to communicate, which can be especially important if children are involved. If the parties disagree on any one issue, the divorce is contested.

The phrase “the devil is in the details” is one that can be applicable in most divorce cases. While the parties my have an overall general consensus on how the marriage should end, most have difficulty putting the general consensus into a detailed divorce agreement. In addition to the divorce agreement, there are many other required forms which must be completed exactly right in order to obtain an uncontested divorce. When you also take into account the fact that different judges have different requirements, even an uncontested divorce can become very complicated to anyone who does not practice divorce law on a regular basis.

We are more than qualified to assist you in preparing a comprehensive settlement agreement and obtaining an uncontested divorce. We can streamline the process and prepare the necessary paperwork so that you don’t even go to court.

			<h2>Modifications</h2>
			<p>Things happen in life. Perhaps your ex-spouse is paying you alimony or child support and has had a substantial increase in income since the divorce. Perhaps your spouse has custody of your children and has gone down a path in life that is not in the best interest of your children. Maybe you were ordered to pay child support and/or alimony and have lost your job for reasons beyond your control. There are many scenarios which would justify a modification of your divorce decree, but you must understand that until the divorce order is modified, the original order stands, and all parties must comply with that order whether they are able to or not. If a party fails to abide by the court’s order, he or she could be held in contempt of court, be ordered to pay attorney fees and court costs, and even incarcerated.</p>

		</div>
	</Layout>
)